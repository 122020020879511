module.exports = [{
      plugin: require('/Users/anderson/Work/rocketon/gatsby/frontpage/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:400,500,700"]}},
    },{
      plugin: require('/Users/anderson/Work/rocketon/gatsby/frontpage/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/anderson/Work/rocketon/gatsby/frontpage/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/anderson/Work/rocketon/gatsby/frontpage/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
